import React, { lazy, Suspense, memo, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { getLightTheme, darkTheme, baseTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { CustomThemeContext } from "../components/util-components/CustomThemeContext";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const [primaryColor, setPrimaryColor] = useState(baseTheme.colorPrimary);
  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light"
      ? { ...getLightTheme(primaryColor) }
      : { ...darkTheme };

  return (
    <CustomThemeContext.Provider value={{ primaryColor, setPrimaryColor }}>
      <ConfigProvider
        theme={themeConfig}
        direction={direction}
        locale={currentAppLocale.antd}
      >
        <Suspense fallback={<Loading cover="content" />}>
          <Layout>
            <Routes />
          </Layout>
        </Suspense>
      </ConfigProvider>
    </CustomThemeContext.Provider>
  );
};

export default memo(Layouts);
