import React from "react";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import("views/auth-views/authentication/login"),
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import("views/auth-views/authentication/register"),
    ),
  },
  {
    key: "register-token",
    path: `${AUTH_PREFIX_PATH}/invitation`,
    component: React.lazy(
      () => import("views/auth-views/authentication/register-token"),
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import("views/auth-views/authentication/forgot-password"),
    ),
  },
  {
    key: "confirm-password",
    path: `${AUTH_PREFIX_PATH}/password-confirmation`,
    component: React.lazy(
      () => import("views/auth-views/authentication/confirm-password"),
    ),
  },
  {
    key: "redeem-campaign",
    path: `${APP_PREFIX_PATH}/redeem-campaign`,
    component: React.lazy(() => import("views/app-views/redeem-campaign")),
  },
  {
    key: "event-campaign",
    path: `${APP_PREFIX_PATH}/event-campaign`,
    component: React.lazy(() => import("views/app-views/event-campaign")),
  },
  {
    key: "cancel-user-invitation",
    path: `${AUTH_PREFIX_PATH}/cancel-invitation`,
    component: React.lazy(() => import("views/app-views/cancel-invitation")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.analytic",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/analytic")),
  },
  {
    key: "dashboard.integrations",
    path: `${APP_PREFIX_PATH}/integrations/:id`,
    component: React.lazy(
      () => import("views/app-views/select-integration-products"),
    ),
  },
  {
    key: "dashboard.integrations.select-products",
    path: `${APP_PREFIX_PATH}/integrations`,
    component: React.lazy(() => import("views/app-views/integrations")),
  },
  {
    key: "dashboard.notifications",
    path: `${APP_PREFIX_PATH}/notifications`,
    component: React.lazy(() => import("views/app-views/notifications-page")),
  },
  {
    key: "users.invitations",
    path: `${APP_PREFIX_PATH}/user-invitations`,
    component: React.lazy(() => import("views/app-views/user-invitations")),
  },
  {
    key: "dashboard.companies",
    path: `${APP_PREFIX_PATH}/company`,
    component: React.lazy(() => import("views/app-views/edit-company")),
  },
  {
    key: "dashboard.products",
    path: `${APP_PREFIX_PATH}/products`,
    component: React.lazy(() => import("views/app-views/product-list")),
  },
  {
    key: "dashboard.packages",
    path: `${APP_PREFIX_PATH}/packages`,
    component: React.lazy(() => import("views/app-views/packages-list")),
  },
  {
    key: "dashboard.edit-packages",
    path: `${APP_PREFIX_PATH}/packages/:id`,
    component: React.lazy(() => import("views/app-views/edit-package")),
  },
  {
    key: "apps.ecommerce.edit-product",
    path: `${APP_PREFIX_PATH}/products/:id`,
    component: React.lazy(() => import("views/app-views/edit-product")),
  },
  {
    key: "apps.ecommerce.add-products",
    path: `${APP_PREFIX_PATH}/products/create`,
    component: React.lazy(() => import("views/app-views/add-product")),
  },
  {
    key: "apps.ecommerce.shipments",
    path: `${APP_PREFIX_PATH}/shipments`,
    component: React.lazy(() => import("views/app-views/shipments")),
  },
  {
    key: "apps.ecommerce.shipments-create-custom",
    path: `${APP_PREFIX_PATH}/shipments/create-custom`,
    component: React.lazy(
      () => import("views/app-views/create-custom-shipment"),
    ),
  },
  {
    key: "apps.ecommerce.shipments-create",
    path: `${APP_PREFIX_PATH}/shipments/send-packages`,
    component: React.lazy(
      () => import("views/app-views/create-packages-shipment"),
    ),
  },
  {
    key: "apps.ecommerce.shipments-create-bulk",
    path: `${APP_PREFIX_PATH}/shipments/send-bulk`,
    component: React.lazy(() => import("views/app-views/create-bulk-shipment")),
  },
  {
    key: "apps.ecommerce.shipments-create-file",
    path: `${APP_PREFIX_PATH}/shipments/send-file`,
    component: React.lazy(() => import("views/app-views/create-file-shipment")),
  },
  {
    key: "apps.ecommerce.shipment-view",
    path: `${APP_PREFIX_PATH}/shipments/:id`,
    component: React.lazy(() => import("views/app-views/view-shipment")),
  },
  {
    key: "pages.settings",
    path: `${APP_PREFIX_PATH}/settings/*`,
    component: React.lazy(() => import("views/app-views/settings")),
  },
  {
    key: "pages.users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("views/app-views/user-list")),
  },
  {
    key: "pages.campaigns",
    path: `${APP_PREFIX_PATH}/campaigns`,
    component: React.lazy(() => import("views/app-views/campaigns")),
  },
  {
    key: "apps.campaigns.edit-campaign",
    path: `${APP_PREFIX_PATH}/campaigns/:id`,
    component: React.lazy(() => import("views/app-views/edit-campaign")),
  },
  {
    key: "apps.campaigns.edit-redeem-campaign",
    path: `${APP_PREFIX_PATH}/redeem-campaigns/:id`,
    component: React.lazy(() => import("views/app-views/edit-redeem-campaign")),
  },
  {
    key: "apps.campaigns.edit-event-campaign",
    path: `${APP_PREFIX_PATH}/event-campaigns/:id`,
    component: React.lazy(() => import("views/app-views/edit-event-campaign")),
  },
  {
    key: "apps.campaigns.create-campaign",
    path: `${APP_PREFIX_PATH}/campaigns/create`,
    component: React.lazy(() => import("views/app-views/create-campaign")),
  },
  {
    key: "apps.campaigns.create-campaign",
    path: `${APP_PREFIX_PATH}/campaigns/create-redeem`,
    component: React.lazy(
      () => import("views/app-views/create-shipping-campaign"),
    ),
  },
  {
    key: "apps.campaigns.create-event-campaign",
    path: `${APP_PREFIX_PATH}/campaigns/create-event`,
    component: React.lazy(
      () => import("views/app-views/create-event-campaign"),
    ),
  },
  {
    key: "pages.closets",
    path: `${APP_PREFIX_PATH}/closets`,
    component: React.lazy(() => import("views/app-views/closets")),
  },
  {
    key: "pages.closets.edit-closet",
    path: `${APP_PREFIX_PATH}/closets/:id`,
    component: React.lazy(() => import("views/app-views/edit-closet")),
  },
  {
    key: "pages.closets.create-closet",
    path: `${APP_PREFIX_PATH}/closets/create`,
    component: React.lazy(() => import("views/app-views/create-closet")),
  },
  {
    key: "pages.recipients",
    path: `${APP_PREFIX_PATH}/recipients`,
    component: React.lazy(() => import("views/app-views/recipients")),
  },
  {
    key: "pages.closets.create-recipient",
    path: `${APP_PREFIX_PATH}/recipients/create`,
    component: React.lazy(() => import("views/app-views/create-recipient")),
  },
  {
    key: "pages.closets.edit-recipient",
    path: `${APP_PREFIX_PATH}/recipients/:id`,
    component: React.lazy(() => import("views/app-views/edit-recipient")),
  },
  {
    key: "pages.closets.create-recipient-address",
    path: `${APP_PREFIX_PATH}/recipients/:id/create`,
    component: React.lazy(
      () => import("views/app-views/create-recipient-address"),
    ),
  },
  {
    key: "pages.closets.edit-recipient-address",
    path: `${APP_PREFIX_PATH}/recipients/:id/address/:address_id`,
    component: React.lazy(
      () => import("views/app-views/edit-recipient-address"),
    ),
  },
  {
    key: "pages.errors.404",
    path: `${APP_PREFIX_PATH}/error/404`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
];
