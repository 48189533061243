import React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {connect, useSelector} from 'react-redux';
import {AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY} from 'configs/AppConfig'
import {getUser} from "store/slices/authSlice";

const ProtectedRoute = ({getUser, user}) => {

    const {token} = useSelector(state => state.auth)
    const location = useLocation()

    if (!token) {
        return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
                         replace/>;
    }

    return <Outlet/>
}

const mapStateToProps = ({auth}) => {
    const {user} = auth;

    return {user}
}

const mapDispatchToProps = {
    getUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)